html {
  font-size: 62.5%;

  &.sr .load-hidden {
    visibility: hidden;
  }
}

body {
  text-align: center;
}
