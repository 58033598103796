#blog {
  background-color: $white-color;
  color: $dark-blue-text;
  margin-top: -10rem;
  padding-top: 15rem;

  @include respond(tab-land) {
    margin-top: 0;
    padding-top: 8rem;
  }

  .blog-wrapper {
    margin-bottom: 2rem;

    @include respond(phone) {
      margin-bottom: 0rem;
    }

    @include respond(tab-land) {
      .project-wrapper__image {
        margin-bottom: 2.5rem;
      }
    }
  }

  a {
    margin-bottom: 20px;
  }

  .card {
    display: flex;
    flex-direction: column;
    border: none;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
    transition: all 0.2s ease-out;
    box-shadow: 0 6px 10px rgba(0, 0, 0, 0.08), 0 0 6px rgba(0, 0, 0, 0.05);
    transition: 0.5s transform cubic-bezier(0.155, 1.105, 0.295, 1.12),
      0.5s box-shadow,
      0.5s -webkit-transform cubic-bezier(0.155, 1.105, 0.295, 1.12);

    @include respond(phone) {
      border: 1px solid lighten($light-grey, 30%);
      box-shadow: none;
      margin-bottom: 3.2rem;
    }
    cursor: pointer;

    img {
      width: 100%;
      height: auto;
    }

    .desc {
      padding: 10px;
      margin: auto;
      width: 100%;
      background: white;
      h3 {
        margin: 0;
        font-size: 1.1em;
        color: $dark-blue-text;
      }

      p {
        margin: 0.5em 0 0 0;
        font-size: 0.7em;
        color: $dark-blue-text;
      }
    }
  }

  .read-more-card {
    color: $white;
    transition: transform 0.3s ease;

    &:hover {
      .arrow-right {
        transform: translateX(10px);
      }
    }

    .desc {
      h3 {
        padding-top: 0.5em;
        padding-bottom: 0.5em;
      }
    }

    .arrow-container {
      height: 200px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: $dark-blue-text;
      background: linear-gradient(135deg, #b4c0e6 0%, #96827e 100%);
    }

    .arrow-right {
      transition: transform 0.3s ease;
      color: $white;
    }
  }
}
