#hero {
  // min-height: 100vh;
  // height: 100vh;
  display: flex;
  align-items: center;
  border-bottom: 0px;
  background: $white-color;
  color: $dark-blue-text;
  padding: 8rem 5.6rem;
  margin-bottom: 0;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: -1;
  overflow: hidden;

  background: url("/src/assets/images/window-lg-transp.png");
  background-position: center;
  background-size: cover;

  .hero-title {
    font-size: 5.6rem;
    font-weight: 400;
    margin-bottom: 3.2rem;
    margin-right: 3rem;
    text-align: right;

    @include respond(tab-land) {
      font-size: 4rem;
    }
    @include respond(tab-port) {
      margin-bottom: 1rem;
      font-size: 3.6rem;
      text-align: center;
    }
    @include respond(phone) {
      font-size: 3.5rem;
      line-height: 1.5;
    }
    @include respond(phone-xs) {
      font-size: 2.8rem;
    }
  }

  .hero-cta {
    display: flex;
    justify-content: end;
    margin-right: 3rem;
    @include respond(tab-port) {
      justify-content: center;
      margin-bottom: 4rem;
      margin-top: 1rem;
    }

    & a {
      font-size: 2.4rem;

      @include respond(phone) {
        font-size: 2rem;
      }
    }
  }

  @include respond(phone) {
    padding: 3.5rem $default-font-size;
    .hero-title,
    .hero-cta {
      margin-right: 0rem;
    }
  }
  @include respond(tab-port) {
    height: fit-content;
  }
}
