#top {
  background: $white-color;
  color: $dark-blue-text;
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  text-decoration: none;
  .top-nav {
    display: flex;
    justify-content: flex-end;
    height: 4rem;
  }

  .top-ul-expanded {
    display: flex;
    margin-right: 2rem;
    margin-bottom: 0;
    font-size: $default-font-size;
    gap: 0.5em;

    li,
    a {
      list-style-type: none;
      color: $dark-blue-text;
      :hover {
        color: $light-red;
      }
    }
  }

  .Navbar {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    padding: 1em;
    font-size: $default-font-size;
  }

  .Navbar__Link {
    padding-right: 8px;
  }

  .Navbar__Items {
    display: flex;

    a {
      font-family: "Karla", sans-serif;
      font-size: calc($default-font-size * 1.2);
    }
  }

  .Navbar__Items--right {
    margin-left: auto;
  }

  .Navbar__Link-toggle {
    display: none;
    overflow: hidden;
    transition: all 12s ease-out;

    i {
      transition: transform 0.3s ease;

      &.toggle {
        transform: rotate(90deg);
        color: $light-red;
      }
    }
  }

  @media only screen and (max-width: 768px) {
    .Navbar__Items,
    .Navbar {
      flex-direction: column;
      overflow: hidden;
      transition: height 200ms;
    }

    .Navbar__Items {
      display: none;
      overflow: hidden;
      transition: height 200ms;
    }

    .Navbar__Items--right {
      margin-left: 0;
      overflow: hidden;
      transition: height 300ms ease-out;
    }

    .Navbar__ToggleShow {
      display: flex;
      overflow: hidden;
      transition: all 12s ease-out;
    }

    .Navbar__Link-toggle {
      align-self: flex-end;
      display: initial;
      cursor: pointer;
      overflow: hidden;
      transition: height 300ms ease-out;

      &:hover,
      &:active {
        transition: all 0.3s ease-out;
        color: $light-red !important;
      }
    }
  }
}
