@import url("https://fonts.googleapis.com/css2?family=Merriweather:ital,wght@0,300;0,400;0,700;0,900;1,300;1,400;1,700;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Karla:ital,wght@0,200..800;1,200..800&display=swap");
h1 {
  font-family: "Karla", sans-serif;
  font-weight: 300;
}

p,
a {
  font-family: "Merriweather", serif;
  font-size: $default-font-size;
}

a,
a:link,
a:hover,
a:visited,
a:active {
  text-decoration: none;
}

a:hover {
  transition: all 0.3s ease-in-out;
}

.section-title {
  margin: 0px;
  margin-bottom: 4.5rem;
  font-size: $big-font-size;
  font-weight: bold;
  text-transform: uppercase;

  @include respond(phone) {
    font-size: 2.8rem;
  }
}

.dark-blue-text {
  color: $dark-blue-text !important;
}

.text-color-main {
  @include supportColorForIE11();

  // Responsive text purple style
  @include respond(phone) {
    background-image: none;
    -webkit-text-fill-color: $secondary-color;
  }

  &:hover {
    transform: translateX(2px);
  }
}
